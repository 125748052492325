import { useState } from "react";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";
import { policies } from "../../policies";

function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  });

  const openModal = (policyName) => {
    const policy = policies.find(policy => policy.name === policyName);
    if(policy) {
      setModalContent({ title: policy.title, content: policy.content });
      setModalOpen(true);
    }
  };

  return (
    <div className="footer">
      <div className="topField">
        <div className="field field1">
          <div className="logo">
            <img src="/assets/essiloss.png" alt="logo" />
          </div>
        </div>
        <div className="field field2">
          <ul>
            <h4>Biz</h4>
            <li className="menuItem">
            <HashLink smooth spy to="#introduction">
              Önsöz
            </HashLink>
            </li>
            <li className="menuItem"><HashLink smooth spy to="#achievements">
              Başarılar
            </HashLink></li>
            <li className="menuItem"><HashLink smooth spy to="#faq">
              Sık Sorulan Sorular
            </HashLink></li>
            <li className="menuItem"><HashLink smooth spy to="#pricing">
              Fiyatlandırma
            </HashLink></li>
            <li className="menuItem"><HashLink smooth spy to="#team">
              Ekip
            </HashLink></li>
            <li className="menuItem"><HashLink smooth spy to="#contact">
              İletişim
            </HashLink></li>
          </ul>
        </div>
        <div className="field field3">
          <ul>
            <h4>Araçlar</h4>
            <li onClick={() => openModal("privacyPolicy")}>Gizlilik Politikası</li>
            <li onClick={() => openModal("termsOfService")}>Hizmet Şartları</li>
            <li onClick={() => openModal("returnPolicy")}>İade Politikası</li>
          </ul>
        </div>
      </div>
      <div className="bottomField">
        <span>2024 Essiloss, ALL RIGHTS RESERVED</span>
        <div className="socialMedia">
          <a target="_blank" href="https://twitter.com/EssilossAcademy">
            <i className="fa-brands fa-x-twitter"></i>
          </a>
        </div>
      </div>

      {modalOpen && (
        <div className="modal policy">
          <div className="modalContent">
            <div className="modalHeader">
              <h4 className="modalTitle">{modalContent.title}</h4>
              <button className="close" onClick={() => setModalOpen(false)}>
                X
              </button>
            </div>
            <div className="modalBody">
              <div className="modalText">
                <pre className="modalText">{modalContent.content}</pre>
              </div>
            </div>
            <div className="modalFooter">
              <button className="btn" onClick={() => setModalOpen(false)}>
                Kapat
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
