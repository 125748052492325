import React from 'react'
import './OurTeam.css'
import TeamCard from '../TeamCard/TeamCard'

function OurTeam() {
    const admin = {
        name: 'Essiloss',
        image: '/assets/essiloss-pp.jpeg',
        twitterLink: 'https://twitter.com/Essiloss'
    }

    const teamList = [
        {
            name: 'Jasper',
            image: '/assets/jasper.jpeg',
            twitterLink: 'https://twitter.com/Jasper_xa12'
        },
        {
            name: 'Beatrixi',
            image: '/assets/beatrixi.jpeg',
            twitterLink: 'https://twitter.com/beatrixiTrade'
        }
    ]
    return (
        <div className='ourTeam' id='team'>
            <h1>Ekibimiz</h1>
            <div className='teamCards' data-aos="fade-right">
                <TeamCard teammate={admin} />
            </div>
            <div className='teamCards' data-aos="fade-left">
                {teamList.map((teammate, i) => (
                    <TeamCard key={i} teammate={teammate} />
                ))}
            </div>
        </div>
    )
}

export default OurTeam