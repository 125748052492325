import React from "react";
import "./Admin.css";
import UsersTable from "../../components/UsersTable/UsersTable";
import SectionAccordion from "../../components/SectionAccordion/SectionAccordion";
import Price from "../../components/Price/Price";
import { useAppContext } from "../../AppContext";

function Admin() {
  const { user } = useAppContext();
  return (
    <>
      {user?.isAdmin && (
        <div className="admin">
          <UsersTable />
          <SectionAccordion />
          <Price />
        </div>
      )}
    </>
  );
}

export default Admin;
