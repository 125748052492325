import React, { useState } from "react";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import { toasts } from "../../components/toasts";
import { validator } from "../../helper";
import { UserAPI } from "../../apiEndpoints";
import { useAppContext } from "../../AppContext";
import { policies } from "../../policies";

function SignUp() {
  const { showLoader, hideLoader } = useAppContext();

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nameSurname: "",
    twitter: "",
    discord: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);
  const [errors, setErrors] = useState({});

  const [policyConfirm, setPolicyConfirm] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
  });

  const openModal = (policyName) => {
    const policy = policies.find((policy) => policy.name === policyName);
    if (policy) {
      setModalContent({ title: policy.title, content: policy.content });
      setModalOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validator(formData, [
      "nameSurname",
      "twitter",
      "discord",
      "email",
      "password",
      "passwordConfirm",
    ]);
    setErrors(validationErrors);

    if (!policyConfirm) {
      toasts.error("Lütfen Gizlilik Politikasını kabul ediniz");
      return;
    }

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      try {
        const response = await UserAPI.signUp({
          nameSurname: formData.nameSurname,
          twitter: formData.twitter,
          discord: formData.discord,
          email: formData.email,
          password: formData.password,
        });
        toasts.success("Başarıyla kayıt oldunuz. Giriş yapabilirsiniz.");
        navigate("/login");
      } catch (e) {
        toasts.error("Bir hata meydana geldi");
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <div className="signUp">
      <form onSubmit={handleSubmit}>
        <div className="form">
          <Link className="logoLink" to="/">
            <img className="logo" src="assets/essiloss.png" alt="logo" />
          </Link>
          <div className="iconInput">
            <i className="fa-solid fa-user"></i>
            <input
              type="text"
              placeholder="İsim Soyisim"
              name="nameSurname"
              autoComplete="true"
              value={formData.nameSurname}
              onChange={handleChange}
            />
          </div>
          {errors.nameSurname && (
            <div className="error">{errors.nameSurname}</div>
          )}
          <div className="iconInput">
            <i className="fa-brands fa-x-twitter"></i>
            <input
              type="text"
              placeholder="Twitter"
              name="twitter"
              autoComplete="true"
              value={formData.twitter}
              onChange={handleChange}
            />
          </div>
          {errors.twitter && <div className="error">{errors.twitter}</div>}
          <div className="iconInput">
            <i className="fa-brands fa-discord"></i>
            <input
              type="text"
              placeholder="Discord"
              name="discord"
              autoComplete="true"
              value={formData.discord}
              onChange={handleChange}
            />
          </div>
          {errors.discord && <div className="error">{errors.discord}</div>}
          <div className="iconInput">
            <i className="fa-solid fa-envelope"></i>
            <input
              type="email"
              placeholder="Eposta"
              name="email"
              autoComplete="true"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          {errors.email && <div className="error">{errors.email}</div>}
          <div className="iconInput">
            <i className="fa-solid fa-lock"></i>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Şifre"
              name="password"
              autoComplete="true"
              value={formData.password}
              onChange={handleChange}
            />
            <i
              className={
                passwordVisible
                  ? "password fa-solid fa-eye"
                  : "password fa-solid fa-eye-slash"
              }
              onClick={() => setPasswordVisible(!passwordVisible)}
            ></i>
          </div>
          {errors.password && <div className="error">{errors.password}</div>}
          <div className="iconInput">
            <i className="fa-solid fa-lock"></i>
            <input
              type={passwordVisibleConfirm ? "text" : "password"}
              placeholder="Şifre Tekrarı"
              name="passwordConfirm"
              autoComplete="true"
              value={formData.passwordConfirm}
              onChange={handleChange}
            />
            <i
              className={
                passwordVisibleConfirm
                  ? "password fa-solid fa-eye"
                  : "password fa-solid fa-eye-slash"
              }
              onClick={() => setPasswordVisibleConfirm(!passwordVisibleConfirm)}
            ></i>
          </div>
          {errors.passwordConfirm && (
            <div className="error">{errors.passwordConfirm}</div>
          )}
          <div className="policies">
            <input
              type="checkbox"
              name="terms"
              value={policyConfirm}
              onChange={() => setPolicyConfirm(!policyConfirm)}
            />
            <p>
              <span
                className="checkmark"
                onClick={() => openModal("privacyPolicy")}
              >
                Gizlilik Politikası
              </span>
              <span>, </span>
              <span
                className="checkmark"
                onClick={() => openModal("termsOfService")}
              >
                Hizmet Şartları
              </span>
              <span> ve </span>
              <span
                className="checkmark"
                onClick={() => openModal("returnPolicy")}
              >
                İade Politikası
              </span>
              'nı onaylıyorum.
            </p>
          </div>
          <button
            className={`btn ${!policyConfirm && "disabledBtn"}`}
            disabled={!policyConfirm}
          >
            Kayıt Ol
          </button>
          <div className="lineText">
            <div className="line"></div>
            <div className="text">Veya</div>
            <div className="line"></div>
          </div>
          <Link to="/login">Giriş Yap</Link>
        </div>
      </form>

      <div className="background">
        <img src="assets/background.svg" alt="svg" />
      </div>

      {modalOpen && (
        <div className="modal policy">
          <div className="modalContent">
            <div className="modalHeader">
              <h4 className="modalTitle">{modalContent.title}</h4>
              <button className="close" onClick={() => setModalOpen(false)}>
                X
              </button>
            </div>
            <div className="modalBody">
              <div className="modalText">
                <pre className="modalText">{modalContent.content}</pre>
              </div>
            </div>
            <div className="modalFooter">
              <button className="btn" onClick={() => setModalOpen(false)}>
                Kapat
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUp;
