import React, { useRef, useEffect, useState } from "react";
import "./VideoFrame.css";
import "shaka-player/dist/controls.css";
import { useAppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import {VideoAPI} from "../../apiEndpoints";

const VideoFrame = ({ url }) => {
  const { user, updateUserData } = useAppContext();
  const [userName, setUserName] = useState("");
  const [playbackUrl, setPlaybackUrl] = useState("");
  const navigate = useNavigate();

  const onErrorEvent = (event) => {
    onError(event.detail);
  };

  const onError = (error) => {
    console.error("Error code", error.code, "object", error);
  };

  useEffect(() => {
    const getPlayer = async () => {
      try {
        if (url) {
          const player = await VideoAPI.license({
            videoId: url,
            name: user.nameSurname,
            email: user.email,
          });
          setPlaybackUrl(player);
        } else {
          setPlaybackUrl("");
        }
      } catch (error) {
        setPlaybackUrl("");
      }
    };
  
    getPlayer();
  }, [userName, url]);

  useEffect(() => {
    const updateUser = async () => {
      await updateUserData();
      if (!user || !user?.isActive) {
        navigate("/page404");
      } else {
        setUserName(user.nameSurname);
      }
    }

    updateUser();
  }, [url]);


  return playbackUrl ? (
    <div className="video-container">
      <iframe
        src={playbackUrl}
        style={{
          width: "100%",
          height: 600,
          border: "none",
          overflow: "hidden",
        }}
        allowFullScreen={true}
        allow={"encrypted-media"}
      ></iframe>
    </div>
  ) : (
    <div className="videoNotFound">
      <i className="fa-solid fa-video-slash"></i>
      <span>Videoya ulaşılamıyor</span>
    </div>
  );
};

export default VideoFrame;
