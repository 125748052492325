import React, { createContext, useContext, useState, useEffect } from "react";
import { UserAPI } from "./apiEndpoints";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const loader = document.querySelector(".loader");

  const showLoader = () => {
    loader.classList.remove("loader--hide");
    document.getElementById("root").classList.add("fullScreenOpacity");
  };
  const hideLoader = () => {
    loader.classList.add("loader--hide");
    document.getElementById("root").classList.remove("fullScreenOpacity");
  };

  const [user, setUser] = useState(null);

  const updateUserData = async () => {
    showLoader();
    try {
      const response = await UserAPI.me();
      if (response.user) {
        setUser(response.user);
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    updateUserData();
  }, []);

  return (
    <AppContext.Provider
      value={{ showLoader, hideLoader, user, updateUserData, setUser }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
