import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./ForgotPassword.css";
import { VerifyAPI } from "../../apiEndpoints";
import { toasts } from "../../components/toasts";
import { useAppContext } from "../../AppContext";

function ForgotPassword() {
  const { showLoader, hideLoader } = useAppContext();

  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleSendVerificationCode = async (e) => {
    e.preventDefault();
    if (email === "") {
      toasts.error("Lütfen tüm alanları doldurunuz.");
      return;
    }
    try {
      showLoader();
      const response = await VerifyAPI.sendVerifyEmail({
        email,
        sendReason: "forgotPassword",
      });
      toasts.success("Doğrulama kodu e-posta adresinize gönderildi.");
      navigate(`/checkVerifyCode/${response.userId}`);
    } catch (error) {
      toasts.error(
        "Doğrulama kodu gönderilemedi. Lütfen e-posta adresinizi kontrol edin ve tekrar deneyin."
      );
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="forgotPassword">
      <form onSubmit={handleSendVerificationCode}>
        <div className="form">
          <Link className="logoLink" to="/">
            <img className="logo" src="assets/essiloss.png" alt="logo" />
          </Link>
          <div className="iconInput">
            <i className="fa-regular fa-envelope"></i>
            <input
              type="email"
              placeholder="Eposta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button className="btn">Kod Gönder</button>
        </div>
      </form>

      <div className="background">
        <img src="assets/background.svg" alt="svg" />
      </div>
    </div>
  );
}

export default ForgotPassword;
