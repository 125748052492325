import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./SetPassword.css";
import { UserAPI, VerifyAPI } from "../../apiEndpoints";
import { toasts } from "../../components/toasts";
import { useAppContext } from "../../AppContext";
import { validator } from "../../helper";

function SetPassword() {
  const { showLoader, hideLoader } = useAppContext();

  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const { reason, userId, verificationCode } = useParams();

  const handleSetPassword = async (e) => {
    e.preventDefault();
    if (password === "" || confirmPassword === "") {
      toasts.error("Lütfen tüm alanları doldurunuz.");
      return;
    }
    if (password !== confirmPassword) {
      toasts.error("Şifreler uyuşmuyor");
      return;
    }
    const validationErrors = validator({ password, confirmPassword }, [
      "password",
      "passwordConfirm",
    ]);
    setErrors(validationErrors);
    try {
      showLoader();
      await UserAPI.updatePassword({ userId, password });
      toasts.success("Şifre başarıyla güncellendi");
      navigate("/login");
    } catch (error) {
      toasts.error("Şifre güncelleme hatası");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (reason === "updatePassword") {
      const checkVerification = async () => {
        try {
          await VerifyAPI.checkVerification({
            userId,
            verificationCode,
          });
        } catch (error) {
          toasts.error("Bir hata ile karşılaşıldı");
          navigate("/login");
        }
      };

      checkVerification();
    }
  }, []);

  return (
    <div className="setPassword">
      <form onSubmit={handleSetPassword}>
        <div className="form">
          <Link className="logoLink" to="/">
            <img className="logo" src="/assets/essiloss.png" alt="logo" />
          </Link>
          <div className="iconInput">
            <i className="fa-solid fa-lock"></i>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Yeni Şifre"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className={
                passwordVisible
                  ? "password fa-solid fa-eye"
                  : "password fa-solid fa-eye-slash"
              }
              onClick={() => setPasswordVisible(!passwordVisible)}
            ></i>
          </div>
          {errors.password && <div className="error">{errors.password}</div>}
          <div className="iconInput">
            <i className="fa-solid fa-lock"></i>
            <input
              type={passwordVisibleConfirm ? "text" : "password"}
              placeholder="Yeni Şifre Tekrarı"
              name="passwordConfirm"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <i
              className={
                passwordVisibleConfirm
                  ? "password fa-solid fa-eye"
                  : "password fa-solid fa-eye-slash"
              }
              onClick={() => setPasswordVisibleConfirm(!passwordVisibleConfirm)}
            ></i>
          </div>
          {errors.password && (
            <div className="error">{errors.passwordConfirm}</div>
          )}
          <button className="btn">Onayla</button>
        </div>
      </form>

      <div className="background">
        <img src="/assets/background.svg" alt="svg" />
      </div>
    </div>
  );
}

export default SetPassword;
