import React, { useState, useEffect } from "react";
import "./Sections.css";
import SectionCard from "../../components/SectionCard/SectionCard";
import { SectionAPI } from "../../apiEndpoints";
import NoData from "../../components/NoData/NoData";
import { useAppContext } from "../../AppContext";
import { toasts } from "../../components/toasts";
import InactiveUserAlert from "../../components/InactiveUserAlert/InactiveUserAlert";
import { useNavigate } from "react-router-dom";

function Sections() {
  const { showLoader, hideLoader, user, updateUserData } = useAppContext();
  const navigate = useNavigate();

  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        showLoader();
        await updateUserData();
        if (!user.isActive) {
          navigate("/page404");
          return;
        }
        const sectionsData = await SectionAPI.getAll();
        setSections(sectionsData);
      } catch (error) {
        toasts.error("Bölümleri getirirken bir hata oluştu");
      } finally {
        hideLoader();
      }
    };

    fetchSections();
  }, []);

  return (
    <div className="sections">
      {user.isActive ? (
        sections.length > 0 ? (
          <div className="sectionCards">
            {sections.map((section, index) => (
              <SectionCard key={index} section={section} />
            ))}
          </div>
        ) : (
          <NoData />
        )
      ) : (
        <InactiveUserAlert />
      )}
    </div>
  );
}

export default Sections;
