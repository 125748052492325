import React from "react";
import "./ContactBadge.css";

function ContactBadge({contact}) {
  return (
    <a
      href={contact.href}
      target="_blank"
      className={`contactBadge ${contact.className}`}
      data-aos="flip-up"
    >
      <i className={contact.icon}></i>
      <span>{contact.channel}</span>
    </a>
  );
}

export default ContactBadge;
