import React, { useState, useEffect } from "react";
import "./Price.css";
import Modal from "react-modal";
import { PricingAPI } from "../../apiEndpoints";
import { toasts } from "../toasts";
import { useAppContext } from "../../AppContext";

function Price() {
  const { showLoader, hideLoader } = useAppContext();
  const [priceUpdateModalVisible, setPriceUpdateModalVisible] = useState(false);
  const [currentPrice, setCurrentPrice] = useState({
    originalPrice: 0,
    discountedPrice: 0,
  });
  const [newPrice, setNewPrice] = useState(currentPrice);

  const priceUpdateConfirm = async () => {
    try {
      showLoader();
      await PricingAPI.update(newPrice);
      await getPrice();
      setPriceUpdateModalVisible(false);
      toasts.success("Fiyat başarıyla değiştirildi");
    } catch (e) {
      toasts.error(e.message);
    } finally {
      hideLoader();
    }
  };

  const getPrice = async () => {
    showLoader();
    try {
      let price = await PricingAPI.get();
      setCurrentPrice(price);
      setNewPrice(price);
    } catch (e) {
      toasts.error("Bir hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
        await getPrice();
      } catch (e) {
        toasts.error("Bir hata meydana geldi");
      } finally {
        hideLoader();
      }
    };
    fetchData();
  }, []);

  return (
    <div className="price">
      <div className="header">
        <h2>Fiyat</h2>
        <button
          className="iconBtnText"
          onClick={() => setPriceUpdateModalVisible(true)}
        >
          <i className="fa-solid fa-edit"></i>Fiyat düzenle
        </button>
      </div>
      <div className="body">
        <label>Asıl fiyat: {currentPrice.originalPrice} $</label>
        <div className="splitLine"></div>
        <label>İndirimli fiyat: {currentPrice.discountedPrice} $</label>
      </div>

      <Modal
        isOpen={priceUpdateModalVisible}
        onRequestClose={() => {
          setPriceUpdateModalVisible(false);
          setNewPrice(currentPrice);
        }}
        style={modalStyle}
      >
        <div className="priceModalContent">
          <div>
            <label>Asıl Fiyat</label>
            <input
              type="number"
              onChange={(e) =>
                setNewPrice({ ...newPrice, originalPrice: e.target.value })
              }
              value={newPrice.originalPrice}
              placeholder="Asıl Fiyat"
            />
          </div>
          <div>
            <label>İndirimli Fiyat</label>
            <input
              type="number"
              onChange={(e) =>
                setNewPrice({
                  ...newPrice,
                  discountedPrice: e.target.value,
                })
              }
              value={newPrice.discountedPrice}
              placeholder="İndirimli Fiyat"
            />
          </div>
        </div>
        <div className="modalButtons">
          <button
            className="btn"
            onClick={() => {
              setPriceUpdateModalVisible(false);
              setNewPrice(currentPrice);
            }}
          >
            İptal
          </button>
          <button className="btn" onClick={priceUpdateConfirm}>
            Onayla
          </button>
        </div>
      </Modal>
    </div>
  );
}

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "12px",
    maxWidth: "400px",
    maxHeight: "300px",
    margin: "auto",
    textAlign: "center",
    gap: "20px",
    fontSize: "16px",
  },
};

export default Price;
