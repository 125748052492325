import React, { useState } from "react";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { UserAPI } from "../../apiEndpoints";
import { toasts } from "../../components/toasts";
import { useAppContext } from "../../AppContext";

function Login() {
  const { showLoader, hideLoader, updateUserData } = useAppContext();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      toasts.error("Lütfen tüm alanları doldurunuz.");
      return;
    }
    try {
      showLoader();
      const response = await UserAPI.login({ email, password });
      if (response.id) {
        localStorage.setItem("userId", response.id);
        toasts.success("Başarıyla giriş yaptınız.");
        await updateUserData();
        navigate("/dashboard/home");
      } else {
        toasts.error(
          "Giriş yapılamadı. Sunucu yanıtı geçersiz. Lütfen tekrar deneyin."
        );
      }
    } catch (error) {
      toasts.error(
        "Giriş yapılamadı. Lütfen bilgilerinizi kontrol edin ve tekrar deneyin."
      );
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="login">
      <form onSubmit={handleLogin}>
        <div className="form">
          <Link className="logoLink" to="/">
            <img className="logo" src="assets/essiloss.png" alt="logo" />
          </Link>
          <div className="iconInput">
            <i className="fa-regular fa-envelope"></i>
            <input
              type="email"
              placeholder="Eposta"
              autoComplete="true"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="iconInput">
            <i className="fa-solid fa-lock"></i>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Şifre"
              autoComplete="true"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <i
              className={
                passwordVisible
                  ? "password fa-solid fa-eye"
                  : "password fa-solid fa-eye-slash"
              }
              onClick={() => setPasswordVisible(!passwordVisible)}
            ></i>
          </div>
          <button className="btn">Giriş Yap</button>
          <div className="lineText">
            <div className="line"></div>
            <div className="text">Veya</div>
            <div className="line"></div>
          </div>
          <div className="bottomPreferences">
            <Link to="/forgotPassword">Şifremi unuttum</Link>
            <Link to="/signUp">Kayıt Ol</Link>
          </div>
        </div>
      </form>

      <div className="background">
        <img src="assets/background.svg" alt="svg" />
      </div>
    </div>
  );
}

export default Login;
