import React, { useState, useEffect } from "react";
import "./Profile.css";
import { VerifyAPI } from "../../apiEndpoints";
import { useAppContext } from "../../AppContext";
import { toasts } from "../../components/toasts";

function Profile() {
  const { showLoader, hideLoader, user } = useAppContext();
  const [userInfo, setUserInfo] = useState({
    createdDate: "",
    nameSurname: "",
    twitter: "",
    discord: "",
    email: "",
  });

  useEffect(() => {
    setUserInfo(user);
  }, []);

  const sendUpdatePasswordEmail = async () => {
    try {
      showLoader();
      const response = await VerifyAPI.sendUpdatePasswordEmail({
        userId: user.id,
        email: user.email,
      });
      toasts.success("Şifre güncelleme maili gönderildi");
    } catch (error) {
      toasts.error("Şifre güncelleme maili gönderilirken bir hata oluştu");
    } finally {
      hideLoader();
    }
  };

  const formatDateTime = (createdDate) => {
    return new Date(createdDate).toLocaleString('tr-TR', {
      timeZone: 'Europe/Istanbul',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="profile">
      <h1>Profil</h1>
      <div className="userInfo">
        <div className="both">
          <div className="info">
            <h4>Kayıt Tarihi:</h4>
            <p>{formatDateTime(userInfo.createdDate)}</p>
          </div>
          <div className="info">
            <h4>İsim Soyisim</h4>
            <p>{userInfo.nameSurname}</p>
          </div>
        </div>
        <div className="both">
          <div className="info">
            <h4>Twitter</h4>
            <p>{userInfo.twitter}</p>
          </div>
          <div className="info">
            <h4>Discord</h4>
            <p>{userInfo.discord}</p>
          </div>
        </div>
        <div className="both">
          <div className="info">
            <h4>Eposta</h4>
            <p>{userInfo.email}</p>
          </div>
          <div className="info">
            <h4>Şifre Güncelleme Maili</h4>
            <button className="btn" onClick={sendUpdatePasswordEmail}>
              Gönder
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
