import React from 'react'
import './Dashboard.css'
import Sidebar from '../../components/Sidebar/Sidebar'
import { Outlet } from 'react-router-dom'

function Dashboard() {
  return (
    <div className='dashboard'>
      <Sidebar />
      <Outlet/>
    </div>
  )
}

export default Dashboard