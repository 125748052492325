import React, { useState, useEffect } from "react";
import "./DashboardHome.css";
import TopicCard from "../../components/TopicCard/TopicCard";
import { TopicAPI } from "../../apiEndpoints";
import NoData from "../../components/NoData/NoData";
import { useAppContext } from "../../AppContext";
import { toasts } from "../../components/toasts";
import InactiveUserAlert from "../../components/InactiveUserAlert/InactiveUserAlert";

function DashboardHome() {
  const { showLoader, hideLoader, user } = useAppContext();

  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        showLoader();
        const allTopics = await TopicAPI.getAll({ orderByNew: true });
        const lastTenTopics = allTopics.slice(-12);
        setTopics(lastTenTopics);
      } catch (error) {
        toasts.error("Konu aranırken hata");
      } finally {
        hideLoader();
      }
    };

    if (user.isActive) {
      fetchTopics();
    }
  }, []);

  return (
    <div className="dashboardHome">
      {user.isActive ? (
        <div>
          <h1 className="dashboardHomeTitle">Son Eklenenler</h1>
          {topics.length > 0 ? (
            <div className="topicCards">
              {topics.map((topic, index) => (
                <TopicCard key={index} topic={topic} />
              ))}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      ) : (
        <InactiveUserAlert />
      )}
    </div>
  );
}

export default DashboardHome;
