import React from "react";
import "./NoData.css";

function NoData() {
  return (
    <div className="noData">
      <img src="/assets/noData.png" alt="no data" />
      <h3>No Data</h3>
    </div>
  );
}

export default NoData;
