import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toasts = {
  error: (msg, position = "top-right") => {
    toast.error(msg, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  },

  success: (msg, position = "top-right") => {
    toast.success(msg, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  },

  warning: (msg, position = "top-right") => {
    toast.warning(msg, {
      position: position,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Flip,
    });
  },
};

// TYPES

// toast.success("Success Notification !", {
//   position: toast.POSITION.TOP_RIGHT,
// });

// toast.error("Error Notification !", {
//   position: toast.POSITION.TOP_CENTER,
// });

// toast.warning("Warning Notification !", {
//   position: toast.POSITION.TOP_LEFT,
// });

// toast.info("Information Notification !", {
//   position: toast.POSITION.BOTTOM_CENTER,
// });

// toast("Default Notification !", {
//   position: toast.POSITION.BOTTOM_LEFT,
// });

// toast("Custom Style Notification with css class!", {
//   position: toast.POSITION.BOTTOM_RIGHT,
//   className: "foo-bar",
// });
