import React from "react";
import "./InactiveUserAlert.css";

function InactiveUserAlert() {
  return (
    <div className="inactiveUserAlert">
      <i className="fa-solid fa-school-lock lock"></i>
      <h3>Videolara erişim için lütfen eğitimi satın alınız.</h3>
      <a href="https://forms.gle/HtU31uyJ3Tis51zh8" target="_blank">
        <button className="btn">Eğitim kayıt formu</button>
      </a>
    </div>
  );
}

export default InactiveUserAlert;
