import React, { useEffect } from "react";
import "./Pagination.css";

function Pagination({ pagination, updatePagination }) {
  const generatePageNumbersPc = (currentPage, totalPages, maxVisiblePages) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        Math.abs(i - currentPage) <= Math.floor(maxVisiblePages / 2)
      ) {
        pageNumbers.push(i);
      } else if (pageNumbers[pageNumbers.length - 1] !== "...") {
        pageNumbers.push("...");
      }
    }
    return pageNumbers;
  };

  const handlePreviousPage = () => {
    if (pagination.currentPage > 1) {
      updatePagination({
        currentPage: pagination.currentPage - 1,
      });
    }
  };

  const handleNextPage = () => {
    if (pagination.currentPage < pagination.totalPages) {
      updatePagination({
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  const renderButtons = () => {
    const pageNumbers = generatePageNumbersPc(
      pagination.currentPage,
      pagination.totalPages,
      2
    );
    const buttons = pageNumbers.map((pageNumber, index) => (
      <button
        key={index}
        className={pagination.currentPage === pageNumber ? "active" : ""}
        onClick={() => {
          if (pageNumber !== "...") {
            updatePagination({
              currentPage: pageNumber,
            });
          }
        }}
      >
        {pageNumber}
      </button>
    ));
    return buttons;
  };

  useEffect(() => {
    pagination.totalPages = Math.ceil(
      pagination.total / pagination.countPerPage
    );
    updatePagination({
      totalPages: Math.ceil(pagination.total / pagination.countPerPage),
    });
  }, [pagination.countPerPage, pagination.total]);

  const pageOptions = [];
  for (let i = 1; i <= pagination.totalPages; i++) {
    pageOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <div className="pagination">
      <div className="totalCounts">Toplam: {pagination.total}/{pagination.activeCount}</div>
      <div className="paginationControl">
      <div className="paginationPc">
        <button onClick={handlePreviousPage}>&laquo;</button>
        {renderButtons()}
        <button onClick={handleNextPage}>&raquo;</button>
      </div>
      <div className="paginationMobile">
        <label htmlFor="page">Sayfa</label>
        <select
          value={pagination.currentPage}
          onChange={(e) => {
            updatePagination({
              currentPage: Number(e.target.value),
              totalPages: Math.ceil(pagination.total / pagination.countPerPage),
            });
          }}
        >
          {pageOptions}
        </select>
      </div>
      </div>
      <div className="showCount">
        <label htmlFor="showCount">Gösterim</label>
        <select
          value={pagination.countPerPage}
          onChange={(e) => {
            pagination.countPerPage = Number(e.target.value);
            pagination.totalPages = Math.ceil(
              pagination.total / pagination.countPerPage
            );
            updatePagination({
              countPerPage: Number(e.target.value),
              totalPages: Math.ceil(pagination.total / e.target.value),
            });
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
}

export default Pagination;
