import React, { useEffect } from "react";
import "./Home.css";
import Introduction from "../../components/Introduction/Introduction";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import OurTeam from "../../components/OurTeam/OurTeam";
import Contact from "../../components/Contact/Contact";
import Faq from "../../components/Faq/Faq";
import Pricing from "../../components/Pricing/Pricing";
import Banner from "../../components/Banner/Banner";
import "aos/dist/aos.css";
import Aos from "aos";
import Achievements from "../../components/Achievements/Achievements";
import { useAppContext } from "../../AppContext";

function Home() {
  const { updateUserData } = useAppContext();

  useEffect(() => {
    Aos.init({
      disable: "mobile",
    });

    updateUserData();
  }, []);

  return (
    <>
      <Navbar />
      <div className="home">
        <Banner />
        <Introduction />
        <Achievements />
        <Faq />
        <Pricing />
        <OurTeam />
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default Home;
