import React from 'react'
import './TopicCard.css'

function TopicCard({topic}) {
    const formatDateTime = (createdDate) => {
        return new Date(createdDate).toLocaleString('tr-TR', {
          timeZone: 'Europe/Istanbul',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
        });
      };
    return (
        <div className='topicCard'>
            <div className='img'>
            <img src='/assets/thumbnail.png' alt='topic' />
            </div>
            <div className='info'>
                <h4>{topic.title.length > 60
                        ? topic.title.substring(0, 60) + "..."
                        : topic.title}</h4>
                <p>{topic.description.length > 100
                      ? topic.description.substring(0, 160) + "..."
                      : topic.description}</p>
                <span>{formatDateTime(topic.createdDate)}</span>
            </div>
        </div>
    )
}

export default TopicCard