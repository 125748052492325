import React, { useState, useEffect } from "react";
import "./Section.css";
import VideoFrame from "../../components/VideoFrame/VideoFrame";
import Summary from "../../components/Summary/Summary";
import { TopicAPI } from "../../apiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../AppContext";

function Section() {
  const { showLoader, hideLoader } = useAppContext();

  const { sectionId, title } = useParams();
  const navigate = useNavigate();
  const [activeTopic, setActiveTopic] = useState(0);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        showLoader();
        const topicsData = await TopicAPI.getBySectionId(sectionId);
        setTopics(topicsData);
      } catch (error) {
        navigate("/page404");
      } finally {
        hideLoader();
      }
    };

    fetchTopics();
  }, []);

  return (
    <div className="section">
      <div className="content">
        <div className="activeTopic">
          <VideoFrame url={topics[activeTopic]?.videoUrl} />
          {topics && topics.length > 0 && (
            <Summary
              title={topics[activeTopic]?.title}
              description={topics[activeTopic]?.description}
            />
          )}
        </div>
        <div className="topics">
          <h1>{title}</h1>
          <ul>
            {topics &&
              topics.map((topic, index) => (
                <li
                  key={index}
                  className={`topic ${activeTopic === index ? "active" : ""}`}
                  onClick={() => setActiveTopic(index)}
                >
                  <h3 className="title">
                    {topic.title.length > 60
                      ? topic.title.substring(0, 60) + "..."
                      : topic.title}
                  </h3>
                  <p className="description">
                    &#x2022;{" "}
                    {topic.description.length > 100
                      ? topic.description.substring(0, 100) + "..."
                      : topic.description}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Section;
