import React from "react";
import "./Page404.css";
import { Link } from "react-router-dom";
import { useAppContext } from "../../AppContext";

function Page404() {
  const { user } = useAppContext();
  return (
    <div className="page404">
      <h1>Opps! Sayfa Bulunamadı</h1>
      <p>Bir şeyler doğru gitmemiş olabilir. Lütfen tekrar deneyin.</p>
      <Link to={user ? "/dashboard/home" : "/"} className="btn">
        Anasayfaya Dön
      </Link>
    </div>
  );
}

export default Page404;
