import React, { useState } from 'react'
import './Faq.css'

function Faq() {
    const [accordion, setAccordion] = useState(0);

    const toggleAccordion = (i) => {
        i === accordion ? setAccordion(0) : setAccordion(i);
    }

    const faqList = [
        {
            question: 'Bu Eğitimde hangi konular yer alıyor?',
            answer: 'Bu eğitim içerisinde Essiloss’un kendi teknik analiz sisteminde kullandığı Price Action konseptleri, scalp stratejileri ve fon yönetimine dair tüm her şey yer almaktadır.'
        },
        {
            question: 'Eğitim sürecinde nasıl destek alabilirim?',
            answer: 'Eğitim süresi boyunca anlamadığınız bir şey ya da kafanıza takılan bir konu olduğunda “Essiloss Trade Academy” discord kanalına istediğiniz zaman soru sorabilir ve daha önce sorulmuş tüm soruları inceleyebilirsiniz.'
        },
        {
            question: 'Bu eğitimi diğer eğitimlerden ayıran fark nedir?',
            answer: 'Bu eğitimde Price Action’dan ziyade Essiloss’un kendine ait Price Action kullanışını yani sadeleştirilmiş, bilgi kirliliğinden arınmış bir Price Action öğreneceksiniz. Bunun yanı sıra Scalp Stratejilerini öğrenip bununla birlikte Fon Yönetimi ile ilgili önemli bilgileri edineceksiniz. Eğitimin sonunda öğrendikleriniz ile birlikte de kendinize ait bir Price Action oluşturma fırsatı yakalayacaksınız.'
        },
        {
            question: 'Herkes ile birebir ilgileniliyor mu?',
            answer: 'Bu bir eğitim sistemidir, mentörlük sistemi değil. Birebir ilgilenmek için çok büyük bir zamana ihtiyaç vardır ve bu bizim ekibimiz için pek mümkün değildir. Bu sebepledir ki sorularınıza cevap bulabilmek için bir discord kanalı kurulmuştur.'
        },
        {
            question: 'Eğitim videolarını indirebilir ya da birden fazla kişi tarafından kullanıma izin var mı?',
            answer: 'Videoların indirilmesi ya da birden fazla kişi tarafından kullanılması yasaktır. Bunun için gerekli yazılımsal güvenlik önlemleri alınmıştır. IP adresleriniz kontrol edilmekte ve sürekli olarak hesabınız inceleme altındadır. Bu kuralı ihlal edenlere ise hukuki olarak var olan haklarımızı kullanmak zorundayız.'
        },
        {
            question: 'Eğitime kimler katılabilir?',
            answer: 'Tüm konular başlangıç seviyesinden ileri seviyeye doğru bir anlatım olduğu için herkes için uygundur. Fakat eğitime dahil olmadan önce temel seviyede Teknik analiz bilginizin olması süreci hızlandıracaktır.'
        },
        {
            question: 'Eğitim videolarına erişim ne kadar olacak?',
            answer: 'Eğitim videolarına erişim için tek seferlik bir ödeme ile ömür boyu erişim sağlayabilirsiniz'
        },
        {
            question: 'Eğitim ücreti tek seferlik mi yoksa aylık mı?',
            answer: 'Eğitim ücreti tek seferliğe mahsustur.'
        }
    ]
    return (
        <div className='faq' id='faq'>
            <h1>Sık Sorulan Sorular</h1>
            <div className='questions'>
            {
                faqList.map((faq, i) => (
                    <div className='accordion' key={i} data-aos={i % 2 === 0 ? "fade-left" : "fade-right"}>
                        <div className='accordionBox'>
                            <div className='accordionHeader' onClick={() => toggleAccordion(i + 1)}>
                                {i + 1 === accordion ? <i className="fa-solid fa-caret-down"></i> : <i className="fa-solid fa-caret-right"></i>}<h4>{faq.question}</h4>
                            </div>
                            {i + 1 === accordion && <div className='content'>
                                <p>{faq.answer}</p>
                            </div>}
                        </div>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default Faq