import React, { useState, useEffect } from "react";
import "./SectionAccordion.css";
import Modal from "react-modal";
import { SectionAPI, TopicAPI } from "../../apiEndpoints";
import NoData from "../NoData/NoData";
import { toasts } from "../toasts";
import { useAppContext } from "../../AppContext";

function SectionAccordion() {
  const { showLoader, hideLoader } = useAppContext();

  const [sectionList, setSectionList] = useState([]);

  const [sectionModalVisible, setSectionModalVisible] = useState(0);
  const [sectionForm, setSectionForm] = useState({
    title: "",
    description: "",
  });
  const [topicModalVisible, setTopicModalVisible] = useState(0);
  const [topicForm, setTopicForm] = useState({
    title: "",
    description: "",
    videoUrl: "",
  });
  const [openSection, setOpenSection] = useState(0);
  const [openTopics, setOpenTopics] = useState(
    Array(sectionList.length).fill(false)
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    itemType: "",
    operateType: "",
    id: "",
  });

  const toggleSection = (i) => {
    setOpenSection(openSection === i ? 0 : i);
    setOpenTopics(Array(sectionList.length).fill(false));
  };

  const toggleTopic = (j) => {
    const newOpenTopics = [...openTopics];
    newOpenTopics[j] = !newOpenTopics[j];
    setOpenTopics(newOpenTopics);
  };

  const toggleDeleteModal = (itemType, itemId = selectedItem.id) => {
    setSelectedItem({
      itemType,
      operateType: "delete",
      id: itemId,
    });
    setDeleteModalVisible(true);
  };

  const deleteItem = async () => {
    try {
      showLoader();
      if (selectedItem.itemType === "topic") {
        await TopicAPI.delete(selectedItem.id);
      } else if (selectedItem.itemType === "section") {
        await SectionAPI.delete(selectedItem.id);
      }
      setDeleteModalVisible(false);
      getSections();
      toasts.success("Silme işlemi başarılı");
    } catch (error) {
      toasts.error("Silme işlemi sırasında hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  const addSectionConfirm = async () => {
    try {
      showLoader();
      await SectionAPI.add(sectionForm);
      setSectionModalVisible(false);
      setSectionForm({ title: "", description: "" });
      getSections();
      toasts.success("Bölüm ekleme başarılı");
    } catch (error) {
      toasts.error("Bölüm ekleme sırasında hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  const addTopicConfirm = async () => {
    try {
      showLoader();
      await TopicAPI.add({ ...topicForm, sectionId: sectionList[openSection - 1]._id });
      setTopicModalVisible(false);
      setTopicForm({ title: "", description: "", videoUrl: "" });
      getSections();
      toasts.success("Konu ekleme başarılı");
    } catch (error) {
      toasts.error("Konu ekleme sırasında hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  const editSectionConfirm = async () => {
    try {
      showLoader();
      await SectionAPI.update(sectionForm, selectedItem.id);
      setSectionModalVisible(false);
      setSectionForm({ title: "", description: "" });
      toasts.success("Bölüm güncelleme başarılı");
      getSections();
    } catch (error) {
      toasts.error("Bölüm güncelleme sırasında hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  const editTopicConfirm = async () => {
    try {
      showLoader();
      await TopicAPI.update(topicForm, selectedItem.id);
      setTopicModalVisible(false);
      setTopicForm({ title: "", description: "", videoUrl: "" });
      toasts.success("Konu güncelleme başarılı");
      getSections();
    } catch (error) {
      toasts.error("Konu güncelleme sırasında hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getSections();
  }, []);

  const getSections = async () => {
    try {
      showLoader();
      const sections = await SectionAPI.getAll();
      const topics = await TopicAPI.getAll();
      sections.forEach((section) => {
        section.topics = [];
        topics.forEach((topic) => {
          if (topic.sectionId === section._id) {
            section.topics.push(topic);
          }
        });
      });
      setSectionList(sections);
    } catch (e) {
      toasts.error("Bir hata meydana geldi");
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="sectionAccordion">
      <div className="header">
        <h2>
          Bölümler <span className="lengthBadge">{sectionList.length}</span>
        </h2>
        <button
          className="iconBtnText"
          onClick={() => {
            setSectionModalVisible(true);
            setSelectedItem({
              itemType: "section",
              operateType: "add",
              id: "",
            });
          }}
        >
          <i className="fa-solid fa-plus"></i>Bölüm Ekle
        </button>
      </div>
      {sectionList.length === 0 ? (
        <NoData />
      ) : (
        sectionList.map((section, i) => (
          <div className="accordion" key={i}>
            <div className="accordionBox accordionSection">
              <div
                className="accordionHeader"
                onClick={() => toggleSection(i + 1)}
              >
                {i + 1 === openSection ? (
                  <i className="fa-solid fa-caret-down"></i>
                ) : (
                  <i className="fa-solid fa-caret-right"></i>
                )}
                <h4>
                  {section.title}{" "}
                  <span className="lengthBadge">{section.topics.length}</span>
                </h4>
              </div>
              {i + 1 === openSection && (
                <div>
                  <div className="content">
                    <div className="sectionButtons">
                      <button
                        className="iconBtnText"
                        onClick={() => {
                          setSectionModalVisible(true);
                          setSectionForm({
                            title: section.title,
                            description: section.description,
                          });
                          setSelectedItem({
                            itemType: "section",
                            operateType: "edit",
                            id: section._id,
                          });
                        }}
                      >
                        <i className="fa-solid fa-edit"></i>Bölümü düzenle
                      </button>
                      <button
                        className="iconBtnText"
                        onClick={() => {
                          setTopicModalVisible(true);
                          setSelectedItem({
                            itemType: "topic",
                            operateType: "add",
                            id: "",
                          });
                        }}
                      >
                        <i className="fa-solid fa-plus"></i> Konu ekle
                      </button>
                    </div>
                    <p>{section.description}</p>
                  </div>
                  {section.topics.length === 0 ? (
                    <NoData />
                  ) : (
                    section.topics.map((topic, j) => (
                      <div className="accordion" key={j}>
                        <div className="accordionBox accordionTopic">
                          <div
                            className="accordionHeader"
                            onClick={() => toggleTopic(j)}
                          >
                            {openTopics[j] ? (
                              <i className="fa-solid fa-caret-down"></i>
                            ) : (
                              <i className="fa-solid fa-caret-right"></i>
                            )}
                            <h4>{topic.title}</h4>
                          </div>
                          {openTopics[j] && (
                            <div className="content">
                              <p>{topic.description}</p>
                              <div className="topicButtons">
                                <button
                                  className="iconBtnText deleteBtn"
                                  onClick={() =>
                                    toggleDeleteModal("topic", topic._id)
                                  }
                                >
                                  <i className="fa-solid fa-trash"></i>Konuyu
                                  sil
                                </button>
                                <button
                                  className="iconBtnText"
                                  onClick={() => {
                                    setTopicModalVisible(true);
                                    setTopicForm({
                                      title: topic.title,
                                      description: topic.description,
                                      videoUrl: topic.videoUrl,
                                    });
                                    setSelectedItem({
                                      itemType: "topic",
                                      operateType: "edit",
                                      id: topic._id,
                                    });
                                  }}
                                >
                                  <i className="fa-solid fa-edit"></i>Konuyu
                                  düzenle
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          </div>
        ))
      )}

      <Modal
        isOpen={sectionModalVisible}
        onRequestClose={() => {
          setSectionModalVisible(false);
          setSectionForm({ title: "", description: "" });
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "12px",
            maxWidth: "500px",
            maxHeight: "450px",
            margin: "auto",
            textAlign: "center",
            gap: "20px",
            fontSize: "16px",
          },
        }}
      >
        <div className="inputWithLabel">
          <label>Başlık</label>
          <input
            type="text"
            onChange={(e) =>
              setSectionForm({ ...sectionForm, title: e.target.value })
            }
            value={sectionForm.title}
            placeholder="Başlık"
          />
        </div>
        <div className="inputWithLabel">
          <label>Açıklama</label>
          <textarea
            onChange={(e) =>
              setSectionForm({ ...sectionForm, description: e.target.value })
            }
            value={sectionForm.description}
            placeholder="Açıklama"
          />
        </div>
        <div className="modalButtons">
          <button
            className="btn"
            onClick={() => {
              setSectionModalVisible(false);
              setSectionForm({ title: "", description: "" });
            }}
          >
            İptal
          </button>
          {
            (selectedItem.operateType === "edit" && selectedItem.itemType === "section") &&
          <button
          className="btn deleteButton"
          onClick={() => {
            toggleDeleteModal("section");
            setSectionModalVisible(false);
          }}
          >
            Bölümü sil
          </button>
          }
          <button
            className="btn"
            onClick={
              selectedItem.operateType === "add"
                ? addSectionConfirm
                : editSectionConfirm
            }
          >
            Onayla
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={topicModalVisible}
        onRequestClose={() => {
          setTopicModalVisible(false);
          setTopicForm({ title: "", description: "", videoUrl: "" });
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "12px",
            maxWidth: "500px",
            maxHeight: "750px",
            margin: "auto",
            textAlign: "center",
            gap: "20px",
            fontSize: "16px",
          },
        }}
      >
        <div className="inputWithLabel">
          <label>Başlık</label>
          <input
            type="text"
            onChange={(e) =>
              setTopicForm({ ...topicForm, title: e.target.value })
            }
            value={topicForm.title}
            placeholder="Başlık"
          />
        </div>
        <div className="inputWithLabel">
          <label>Video URL</label>
          <input
            type="text"
            onChange={(e) =>
              setTopicForm({ ...topicForm, videoUrl: e.target.value })
            }
            value={topicForm.videoUrl}
            placeholder="Video URL"
          />
        </div>
        <div className="inputWithLabel">
          <label>Açıklama</label>
          <textarea
            onChange={(e) =>
              setTopicForm({ ...topicForm, description: e.target.value })
            }
            value={topicForm.description}
            placeholder="Açıklama"
          />
        </div>
        <div className="modalButtons">
          <button
            className="btn"
            onClick={() => {
              setTopicModalVisible(false);
              setTopicForm({ title: "", description: "", videoUrl: "" });
            }}
          >
            İptal
          </button>
          <button
            className="btn"
            onClick={
              selectedItem.operateType === "add"
                ? addTopicConfirm
                : editTopicConfirm
            }
          >
            Onayla
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={deleteModalVisible}
        onRequestClose={() => setDeleteModalVisible(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "12px",
            maxWidth: "400px",
            maxHeight: "230px",
            margin: "auto",
            textAlign: "center",
            gap: "20px",
            fontSize: "16px",
          },
        }}
      >
        <div className="deleteConfirmModal">
          <i className="fa-solid fa-circle-exclamation"></i>
          <span>Kayıt silinecek. Devam etmek istiyor musunuz ?</span>
        </div>
        <div className="modalButtons">
          <button className="btn" onClick={() => setDeleteModalVisible(false)}>
            İptal
          </button>
          <button className="btn" onClick={() => deleteItem()}>
            Onayla
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default SectionAccordion;
