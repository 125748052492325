import { Route, Routes, useNavigate } from "react-router-dom";
import Page404 from "./pages/Page404/Page404";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import CheckVerifyCode from "./pages/CheckVerifyCode/CheckVerifyCode";
import SetPassword from "./pages/SetPassword/SetPassword";
import DashboardHome from "./pages/DashboardHome/DashboardHome";
import Section from "./pages/Section/Section";
import Admin from "./pages/Admin/Admin";
import Sections from "./pages/Sections/Sections";
import { ToastContainer } from "react-toastify";
import { useAppContext } from "./AppContext";
import Profile from "./components/Profile/Profile";
import { useEffect } from "react";

function App() {
  const { user } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const restrictedPaths = ["/login", "/signUp", "/forgotPassword", "/checkVerifyCode"];
      if (restrictedPaths.includes(window.location.pathname)) {
        navigate("/dashboard/home");
      }
    }
  }, [user, navigate]);
  return (
    <div className="appContainer">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/checkVerifyCode/:userId" element={<CheckVerifyCode />} />
        {user && (
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="home" element={<DashboardHome />} />
            {user.isActive && (
              <Route path="sections/:title/:sectionId" element={<Section />} />
            )}
            <Route path="sections" element={<Sections />} />
            <Route path="profile" element={<Profile />} />
            {user.isAdmin && <Route path="admin" element={<Admin />} />}
          </Route>
        )}
        <Route
          path="/setPassword/:reason/:userId/:verificationCode"
          element={<SetPassword />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
