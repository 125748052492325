import React from 'react'
import './TeamCard.css'

function TeamCard({ teammate }) {
    return (
        <div className='teamCard'>
            <a href={teammate.twitterLink} target='_blank' rel='noopener noreferrer'>
                <img src={teammate.image} alt='teamCard' />
                <div className='badge'>
                    <i className="fa-brands fa-x-twitter"></i>
                    <span>{teammate.name}</span>
                </div>
            </a>
        </div>
    )
}

export default TeamCard