export const validator = (req, fields) => {
  const fieldMap = {
    nameSurname: "İsim Soyisim",
    twitter: "Twitter Kullanıcı Adı",
    discord: "Discord Kullanıcı Adı",
    email: "E-posta Adresi",
    password: "Şifre",
    passwordConfirm: "Şifre Onayı",
  };

  const errors = {};

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{6,}$/;

  for (const field of fields) {
    const fieldName = fieldMap[field];
    if (!req[field]) {
      errors[field] = `${fieldName} zorunludur`;
    } else if (field === "email" && !/\S+@\S+\.\S+/.test(req[field])) {
      errors[field] = `Geçersiz ${fieldName}`;
    } else if (field === "password" && !passwordRegex.test(req[field])) {
      errors[field] =
        "Şifre en az 6 karakter olmalı, en az 1 harf ve 1 rakam içermelidir";
    } else if (field === "verifyCode" && req[field].length !== 6) {
      errors[field] = "Geçersiz doğrulama kodu";
    }
  }

  if (fields.includes("password") && fields.includes("passwordConfirm")) {
    const { password, passwordConfirm } = req;
    if (password !== passwordConfirm) {
      errors.passwordConfirm = "Şifreler uyuşmuyor";
    }
  }

  return errors;
};
