import React from "react";
import "./Contact.css";
import ContactBadge from "../ContactBadge/ContactBadge";

function Contact() {
  return (
    <div className="contact" id="contact">
      <h1>İletişim</h1>
      <div className="contactOptions">
        {/* <a href='https://discord.gg/PrhUFDSGv3' target='_blank' className='discord' data-aos="fade-right">
                    <i className='fa-brands fa-discord'></i>
                    <span>Discord</span>
                </a> */}
        <ContactBadge
          contact={{
            icon: "fa-brands fa-x-twitter",
            channel: "x.com/EssilossAcademy",
            href: "https://x.com/EssilossAcademy",
            className: "twitter",
          }}
        />
        <ContactBadge
          contact={{
            icon: "fa-regular fa-envelope",
            channel: "essiloss.trade@gmail.com",
            href: "mailto:essiloss.trade@gmail.com",
            className: "email",
          }}
        />
        {/* <a href='https://www.instagram.com/essilosstradeacademy/' target='_blank' className='instagram' data-aos="fade-left">
                    <i className="fa-brands fa-instagram"></i>
                    <span>İnstagram</span>
                </a> */}
      </div>
    </div>
  );
}

export default Contact;
