import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { NavLink, useNavigate } from "react-router-dom";
import { UserAPI } from "../../apiEndpoints";
import { useAppContext } from "../../AppContext";
import { toasts } from "../toasts";

function Sidebar() {
  const { showLoader, hideLoader, user, setUser } = useAppContext();

  const [openSidebar, setOpenSidebar] = useState(false);
  const [tabs, setTabs] = useState([
    {
      title: "Anasayfa",
      icon: "fa-solid fa-house",
      path: "home",
    },
    {
      title: "Discord",
      icon: "fa-brands fa-discord",
      path: "https://discord.gg/PrhUFDSGv3",
    },
    {
      title: "Eğitim Bölümleri",
      icon: "fa-solid fa-book-open-reader",
      path: "sections",
    },
    {
      title: "Profil",
      icon: "fa-solid fa-user",
      path: "profile",
    },
  ]);

  useEffect(() => {
    if (!tabs.find(tab => tab.path === "admin") && user?.isAdmin) {
      setTabs(prevState => [
        ...prevState,
        {
          title: "Admin",
          icon: "fa-solid fa-user-tie",
          path: "admin",
        },
      ]);
    }
  }, [user]);

  const navigate = useNavigate();

  const logout = async () => {
    showLoader();
    try {
      await UserAPI.logout();
      setUser(null);
      localStorage.removeItem("userId");
      hideLoader();
      navigate("/");
    } catch (err) {
      hideLoader();
      toasts.error("Logout failed");
    } finally {
      hideLoader();
    }
  };

  return (
    <div className={`sidebar ${openSidebar ? "" : "closed"}`}>
      <button
        className="siderbarToggleBtn"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        {openSidebar ? (
          <i className="fa-solid fa-xmark"></i>
        ) : (
          <i className="fa-solid fa-bars"></i>
        )}
      </button>
      <div className={`sidebarContent ${openSidebar ? "" : "closed"}`}>
        <ul>
          {tabs.map((tab, i) => (
            <NavLink
              to={tab.path}
              target={tab.title === "Discord" ? "_blank" : "_self"}
              key={i}
            >
              <li>
                <i className={tab.icon}></i>
                <span>{tab.title}</span>
              </li>
            </NavLink>
          ))}
        </ul>
      </div>
      <button onClick={logout} className="logoutBtn">
        <i className="fa-solid fa-right-from-bracket"></i>
      </button>
    </div>
  );
}

export default Sidebar;
