import React from 'react'
import './Achievements.css'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function Achievements() {
    return (
        <div className='achievements' id='achievements'>
            <h1>Başarılar</h1>
            <div className='swiperDiv' data-aos="fade-up">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={80}
                    slidesPerView={3}
                    autoplay={{ delay: 5000 }}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                            slidesPerView: 1
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 70
                        },
                        1800: {
                            spaceBetween: 100
                        }
                    }}
                >
                    <SwiperSlide>
                        <img src='/assets/payout1.png' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout2.png' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout7.jpeg' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout3.png' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout4.png' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout5.png' alt='achievement' />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src='/assets/payout6.jpeg' alt='achievement' />
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Achievements