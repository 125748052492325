import axios from "axios";

const API = axios.create({
  baseURL: "https://api.essiloss.com/api",
  // baseURL: "http://localhost:4000/api",
  withCredentials: true,
});

// USER
export const UserAPI = {
  getAll: async (req) => {
    const response = await API.post("/user", req);
    return response.data;
  },

  login: async (req) => {
    const response = await API.post("/session", req);
    return response.data;
  },

  logout: async (req) => {
    const response = await API.delete("/session", req);
    return response.data;
  },

  me: async (req) => {
    const response = await API.get("/session", req);
    return response.data;
  },

  signUp: async (req) => {
    const response = await API.post("/user/create", req);
    return response.data;
  },

  updatePassword: async (req) => {
    const response = await API.put(`/user/updatePw`, req);
    return response.data;
  },

  deleteUser: async (userId) => {
    const response = await API.put(`/user/delete/${userId}`);
    return response.data;
  },

  changeActivity: async (req) => {
    const response = await API.put(`/user/changeActivity`, req);
    return response.data;
  },
};

// VERIFY
export const VerifyAPI = {
  sendVerifyEmail: async (req) => {
    const response = await API.post(`/verify/sendVerifyEmail`, req);
    return response.data;
  },
  sendUpdatePasswordEmail: async (req) => {
    const response = await API.post(`/verify/sendUpdatePasswordEmail`, req);
    return response.data;
  },
  checkVerification: async (req) => {
    const response = await API.put("/verify/checkVerification", req);
    return response.data;
  },
};

// SECTION
export const SectionAPI = {
  getAll: async () => {
    const response = await API.get("/section");
    return response.data;
  },

  add: async (req) => {
    const response = await API.post("/section", req);
    return response.data;
  },

  delete: async (sectionId) => {
    const response = await API.delete(`/section/${sectionId}`);
    return response.data;
  },

  update: async (req, sectionId) => {
    const response = await API.put(`/section/${sectionId}`, req);
    return response.data;
  },
};

export const TopicAPI = {
  getAll: async (req) => {
    const response = await API.get("/topic/getAll", req);
    return response.data;
  },

  getBySectionId: async (sectionId) => {
    const response = await API.get(`/topic/getBySectionId/${sectionId}`);
    return response.data;
  },

  add: async (req) => {
    const response = await API.post("/topic", req);
    return response.data;
  },

  delete: async (topicId) => {
    const response = await API.delete(`/topic/${topicId}`);
    return response.data;
  },

  update: async (req, topicId) => {
    const response = await API.put(`/topic/${topicId}`, req);
    return response.data;
  },
};

export const PricingAPI = {
  get: async () => {
    const response = await API.get("/pricing");
    return response.data;
  },

  update: async (req) => {
    const response = await API.put(`/pricing`, req);
    return response.data;
  },
};

export const VideoAPI = {
  license: async (req) => {
    const response = await API.post("/license", req);
    return response.data;
  },
};

export default API;
