export const policies = [
    {
        name: "privacyPolicy",
        title: "Gizlilik Politikası",
        content: `
        1. Veri Toplama ve Kullanımı:
        - Sitemize ziyaret ettiğinizde, kişisel verilerinizi (ad, e-posta, telefon numarası vb.) toplayabiliriz.
        
        - Bu veriler, hizmet sunmak, işlemleri gerçekleştirmek, soruları yanıtlamak ve size özel fırsatlar sunmak için kullanılabilir.
        
        - Kişisel verilerinizin mahremiyetini korumak için gerekli önlemleri alıyoruz ve bilgilerinizi üçüncü taraflarla paylaşmıyoruz, satmıyoruz veya kiralamıyoruz.
        
        2. Çerezler:
        - Web sitemizde çerezler kullanıyoruz. Çerezler, sitemizin daha etkin çalışmasına yardımcı olur ve size özelleştirilmiş bir deneyim sunar.
        
        - Çerezler, tarayıcınızın hafızasına yerleştirilen küçük metin dosyalarıdır ve kimlik bilgilerinizi içermez. Çerezleri kabul etmeyi veya reddetmeyi tercih edebilirsiniz.
        
        3. İçerik Kullanımı ve Paylaşımı:
        - Sitemizde bulunan tüm içerikler (metinler, resimler, videolar, ses kayıtları vb.) telif hakkıyla korunmaktadır.
        
        - Bu içeriklerin kopyalanması, indirilmesi veya başka bir platformda paylaşılması kesinlikle yasaktır.
        
        - İçeriklerimizi yalnızca kişisel kullanımınız için görüntüleyebilirsiniz ve ticari veya diğer amaçlarla kullanamazsınız.
        
        - İçeriklerimizin izinsiz kullanımı durumunda yasal haklarımızı koruma hakkımızı saklı tutuyoruz.
        
        4. Güvenlik:
        - Kişisel verilerinizin güvenliğini sağlamak için uygun fiziksel, elektronik ve yönetimsel önlemleri alıyoruz.
        
        - Sitemizde verilerinizi korumak için SSL (Güvenli Yuva Katmanı) teknolojisini kullanıyoruz.
        
        - Ancak, internet üzerinden veri iletimlerinin tamamen güvenli olduğunu garanti edemeyiz. Veri iletiminde herhangi bir güvenlik sorunu yaşanması durumunda hızlı bir şekilde müdahale eder ve sorunu çözeriz.
        
        5. Üçüncü Taraf Bağlantıları:
        - Sitemizde üçüncü taraf bağlantıları bulunabilir. Bu bağlantılar sizi farklı web sitelerine yönlendirebilir.
        
        - Bu üçüncü taraf sitelerin gizlilik politikalarından sorumlu değiliz. Bu siteleri ziyaret etmeden önce kendi gizlilik politikalarını okumanızı öneririz.
        
        - Bu gizlilik politikası, sitemizi ziyaret ettiğinizde kişisel verilerinizi nasıl topladığımızı ve kullandığımızı açıklar. Lütfen bu kılavuzu dikkatlice okuyunuz. Gizlilik kılavuzumuzu zaman zaman güncelleyebiliriz, bu nedenle düzenli olarak kontrol etmeniz önemlidir. 
        `
    },
    {
        name: "termsOfService",
        title: "Hizmet Şartları",
        content: `
        Hoş Geldiniz! Essiloss Trade Academy'yi kullanmadan önce lütfen aşağıdaki hizmet şartlarını dikkatlice okuyun.

        1. Kullanım Şartları
        - Essiloss Trade Academy'yi kullanarak, aşağıdaki şartları kabul etmiş olursunuz:
        
        - Sitedeki tüm içeriklerin telif hakları Essiloss Trade Academy'ye aittir ve izin almadan kopyalanamaz, indirilemez veya herhangi bir platformda paylaşılamaz.
        
        - Sitede yer alan eğitim kurslarına katılmak için kayıt olmalısınız ve belirtilen ücreti ödemelisiniz.
        
        - Hesabınızı başkalarıyla paylaşmamalı veya başka bir kişi adına hesap oluşturmamalısınız.
        
        - Sitenin kullanımı sırasında, diğer kullanıcıların mahremiyetine ve haklarına saygılı olmalısınız.
        
        2. Kurs Kaydı ve Ödeme
        - Eğitim kurslarına kaydolmak için doğru ve güncel bilgiler sağlamalısınız.
        
        - Kurs ücretlerini belirtilen yöntemlerle ödemelisiniz.
        
        - Ödeme işlemleri, güvenli bir şekilde gerçekleşir. Ödeme bilgilerinizi güvende tutmak için gerekli önlemleri alıyoruz.
        
        3. İptal ve İade
        - Kursa kayıt yaptırdıktan sonra, iptal veya iade talepleri için belirli bir politika uygulanır.
        
        - İptal ve iade politikaları, kursa ve satın alınan hizmete bağlı olarak değişebilir.
        
        - İptal veya iade taleplerinizi belirli bir süre içinde bildirmeniz gerekebilir.
        
        - İptal veya iade taleplerinizin onaylanması durumunda, ödeme iadesi belirli prosedürlere göre gerçekleştirilir.
        
        4. Kullanıcı Sorumluluğu
        - Essiloss Trade Academy'yi kullanırken yasalara uymalı ve diğer kullanıcıların haklarına saygı göstermelisiniz.
        - Sitedeki içerikleri yanlış kullanmamalı veya başkalarının haklarını ihlal etmemelisiniz.
    
        - Sitede paylaşılan içeriklere yorum yaparken saygılı ve yapıcı olmalısınız.
        
        - Sitenin güvenliğini tehlikeye atacak herhangi bir eylemden kaçınmalısınız.
        
        5. Değişiklikler ve İptal Etme Hakkı
        - Essiloss Trade Academy, hizmet şartlarını değiştirme veya iptal etme hakkını saklı tutar.
        
        - Değişiklikler veya iptaller, kullanıcılara önceden bildirilir veya güncellenmiş hizmet şartları sitemizde yayınlanır.
        
        6. Gizlilik Kılavuzu
        - Kişisel verilerinizi nasıl topladığımız, kullandığımız ve paylaştığımız hakkında daha fazla bilgi için Gizlilik Kılavuzumuzu inceleyin.
        
        Bu hizmet şartları, Essiloss Trade Academy ile siz arasındaki ilişkiyi düzenler. Sitenin kullanımı, bu şartlarını kabul ettiğiniz anlamına gelir.
        
        Herhangi bir sorunuz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.
        `
    },
    {
        name: "returnPolicy",
        title: "İade Politikası",
        content: `
        1. İade Koşulları:
        - İade talepleri, satın alınan dijital eğitim programına erişim sağlamadan önce yapılmalıdır.
        
        - İade talebi, dijital eğitim programı tamamlanmadan önce yapılmış olmalıdır.
        
        - İade talebi için geçerli bir gerekçe sunulmalıdır, örneğin teknik bir sorun veya eksik/yanlış içerik gibi.
        
        - İade talebi, müşteri hizmetlerimizle iletişime geçilerek yapılmalıdır ve gerekli bilgiler sağlanmalıdır.
        
        2. İade Süreci:
        - İade talebi alındıktan sonra, talebinizi değerlendirmek için gereken süreci başlatacağız.
        
        - İade talebiniz incelenirken, satın aldığınız dijital eğitim programının kullanım durumu ve diğer ilgili bilgiler gözden geçirilecektir.
        
        - Talebiniz onaylandığında, iade işlemi gerçekleştirilecek ve ödeme, kullanılan ödeme yöntemine bağlı olarak ilgili hesaba iade edilecektir.
        
        - İade süreci, talebiniz doğrultusunda en kısa sürede tamamlanmaya çalışılacaktır.
        
        3. İade Kabul Edilmeyen Durumlar:
        - Dijital eğitim programının tamamlanmış olduğu durumlar.
        
        - İade talebinin belirtilen süre içinde yapılmamış olması.
        
        - İade talebinde geçerli bir gerekçenin sunulmaması veya talebin yeterli şekilde desteklenmemesi durumları.
        
        - Çoklu hesap kullanımı, İçerik kaydetme, İndirme ve diğer platformlarda paylaşma gibi durumlarda.
        
        Müşteri memnuniyetine ve adil bir iade sürecine önem verdiğimizi belirtmek isteriz. İade talepleriyle ilgili olarak her zaman size yardımcı olmak için buradayız ve herhangi bir sorunuz veya endişeniz olduğunda lütfen bizimle iletişime geçmekten çekinmeyin.
        `
    }
];    
