import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./CheckVerifyCode.css";
import { VerifyAPI } from "../../apiEndpoints";
import { toasts } from "../../components/toasts";
import { useAppContext } from "../../AppContext";

function CheckVerifyCode() {
  const { showLoader, hideLoader } = useAppContext();
  const navigate = useNavigate();

  const [verificationCode, setVerificationCode] = useState("");
  const { userId } = useParams();

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (verificationCode === "") {
      toasts.error("Lütfen tüm alanları doldurunuz.");
      return;
    }
    try {
      showLoader();
      const response = await VerifyAPI.checkVerification({
        userId,
        verificationCode,
      });
      toasts.success(
        "Kod doğrulandı. Şimdi yeni şifrenizi ayarlayabilirsiniz."
      );
      navigate(`/setPassword/forgotPassword/${userId}/${verificationCode}`);
    } catch (error) {
      toasts.error(
        "Kod doğrulaması başarısız oldu. Lütfen kodunuzu kontrol edin ve tekrar deneyin."
      );
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="checkVerifyCode">
      <form onSubmit={handleVerifyCode}>
        <div className="form">
          <Link className="logoLink" to="/">
            <img className="logo" src="/assets/essiloss.png" alt="logo" />
          </Link>
          <div className="iconInput">
            <i className="fa-regular fa-envelope"></i>
            <input
              type="text"
              placeholder="Kod"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
          <button className="btn">Onayla</button>
        </div>
      </form>

      <div className="background">
        <img src="/assets/background.svg" alt="svg" />
      </div>
    </div>
  );
}

export default CheckVerifyCode;
