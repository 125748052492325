import React, { useState, useEffect } from "react";
import "./SectionCard.css";
import { Link } from "react-router-dom";
import { TopicAPI, UserAPI } from "../../apiEndpoints";
import { useAppContext } from "../../AppContext";
import { toasts } from "../toasts";

function SectionCard({ section }) {
  const { showLoader, hideLoader, user } = useAppContext();

  const [topicCount, setTopicCount] = useState(0);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        showLoader();
        const topics = await TopicAPI.getBySectionId(section._id);
        setTopicCount(topics.length);
      } catch (error) {
        toasts.error("Konu aranırken hata");
      } finally {
        hideLoader();
      }
    };

    fetchTopics();
  }, []);

  const activateMsg = () => {
    toasts.warning("Hesabız aktif değil. Lütfen admin ile iletişime geçin");
  };

  return (
    <div className="sectionCard">
      {user?.isActive || user?.isAdmin ? (
        <Link to={`/dashboard/sections/${section.title}/${section._id}`}>
          <i className="fa-solid fa-book"></i>
          {section.title} ( {topicCount} ders )
        </Link>
      ) : (
        <div onClick={activateMsg}>
          <i className="fa-solid fa-book"></i>
          {section.title} ( {topicCount} ders )
        </div>
      )}
    </div>
  );
}

export default SectionCard;
