import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { AppProvider } from "./AppContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AppProvider>
        <App />
    </AppProvider>
  </Router>
);
