import React, { useState, useEffect } from "react";
import "./UsersTable.css";
import Modal from "react-modal";
import { UserAPI } from "../../apiEndpoints";
import { toasts } from "../toasts";
import NoData from "../../components/NoData/NoData";
import Pagination from "../Pagination/Pagination";
import { useAppContext } from "../../AppContext";

function UsersTable() {
  const { showLoader, hideLoader } = useAppContext();
  const [confirmUserModalVisible, setConfirmUserModalVisible] = useState(false);
  const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [search, setSearch] = useState({
    isActive: "",
    search: "",
  });
  const [pagination, setPagination] = useState({
    total: 1,
    countPerPage: 5,
    currentPage: 1,
    totalPages: 1,
    activeCount: 0,
  });

  const updatePagination = (newState) => {
    setPagination((prevPagination) => {
      const updatedPagination = { ...prevPagination, ...newState };
      if (updatedPagination.currentPage > updatedPagination.totalPages) {
        updatedPagination.currentPage = updatedPagination.totalPages;
      }
      return updatedPagination;
    });
  };

  useEffect(() => {
    getUsers();
  }, [search.isActive, pagination.currentPage, pagination.countPerPage]);

  const getUsers = async () => {
    try {
      showLoader();
      const fetchedUsers = await UserAPI.getAll(search);
      const startIndex = (pagination.currentPage - 1) * pagination.countPerPage;
      const endIndex = startIndex + pagination.countPerPage;
      const paginatedUsers = fetchedUsers.slice(startIndex, endIndex);
      const activeCount = fetchedUsers.filter((user) => user.isActive).length;
      setUsers(paginatedUsers);
      updatePagination({
        total: fetchedUsers.length,
        totalPages: Math.ceil(fetchedUsers.length / pagination.countPerPage),
        activeCount,
      });
    } catch (error) {
      toasts.error("Kullanıcı aranırken hata");
    } finally {
      hideLoader();
    }
  };

  const deleteUser = async () => {
    try {
      showLoader();
      await UserAPI.deleteUser(selectedUser._id);
      setDeleteUserModalVisible(false);
      toasts.success("Kullanıcı başarıyla silindi.");
      getUsers();
    } catch (error) {
      toasts.error("Kullanıcı silinirken bir hata oluştu.");
    } finally {
      hideLoader();
    }
  };

  const changeActivity = async (isActive) => {
    try {
      showLoader();
      await UserAPI.changeActivity({ id: selectedUser._id, isActive });
      setConfirmUserModalVisible(false);
      if (isActive) {
        toasts.success("Kullanıcı başarıyla etkinleştirildi.");
      } else {
        toasts.success("Kullanıcı başarıyla deaktif edildi.");
      }
      getUsers();
    } catch (error) {
      if (isActive) {
        toasts.error("Kullanıcı etkinleştirilirken bir hata oluştu.");
      } else {
        toasts.error("Kullanıcı deaktif edilirken bir hata oluştu.");
      }
    } finally {
      hideLoader();
    }
  };

  const handleSelectChange = (e) => {
    const newIsActive = e.target.value;
    setSearch({ ...search, isActive: newIsActive });
  };

  const formatDateTime = (createdDate) => {
    return new Date(createdDate).toLocaleString("tr-TR", {
      timeZone: "Europe/Istanbul",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="usersTable">
      <div className="header">
        <h2>Kayıtlı üyeler</h2>
        <div className="filterDiv">
          <select onChange={handleSelectChange} value={search.isActive}>
            <option value="">Tümü</option>
            <option value="active">Aktif</option>
            <option value="deactive">Deaktif</option>
          </select>
          <div className="searchDiv">
            <input
              value={search.search}
              onChange={(e) => setSearch({ ...search, search: e.target.value })}
              type="text"
              placeholder="Kullanıcı ara"
            />
            <button onClick={getUsers}>
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </div>
      {users.length > 0 ? (
        <div>
          <div className="tableWrapper">
            <div>
              <table>
                <thead>
                  <tr>
                    <th>İsim soyisim</th>
                    <th>Email</th>
                    <th>Twitter</th>
                    <th>Discord</th>
                    <th>Kayıt tarihi</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user._id}>
                      <td>
                        {user.nameSurname}{" "}
                        {user.isAdmin && (
                          <i className="fa-solid fa-user-tie"></i>
                        )}
                      </td>
                      <td>{user.email}</td>
                      <td>{user.twitter}</td>
                      <td>{user.discord}</td>
                      <td>{formatDateTime(user.createdDate)}</td>
                      <td className="operations">
                        <button
                          className={`iconBtn ${
                            user.isActive ? "deactivateBtn" : "activateBtn"
                          }`}
                          onClick={() => {
                            setSelectedUser(user);
                            setConfirmUserModalVisible(true);
                          }}
                        >
                          {user.isActive ? (
                            <i className="fa-solid fa-square-xmark"></i>
                          ) : (
                            <i className="fa-solid fa-square-check"></i>
                          )}
                        </button>
                        <button
                          className="iconBtn deleteBtn"
                          onClick={() => {
                            setSelectedUser(user);
                            setDeleteUserModalVisible(true);
                          }}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            pagination={pagination}
            updatePagination={updatePagination}
          />
        </div>
      ) : (
        <NoData />
      )}

      <Modal
        isOpen={deleteUserModalVisible}
        onRequestClose={() => setDeleteUserModalVisible(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "12px",
            maxWidth: "400px",
            maxHeight: "230px",
            margin: "auto",
            textAlign: "center",
            gap: "20px",
            fontSize: "16px",
          },
        }}
      >
        <div className="deleteConfirmModal">
          <i className="fa-solid fa-circle-exclamation"></i>
          <span>Kullanıcı silinecek. Devam etmek istiyor musunuz ?</span>
        </div>
        <div className="modalButtons">
          <button
            className="btn"
            onClick={() => setDeleteUserModalVisible(false)}
          >
            İptal
          </button>
          <button className="btn" onClick={deleteUser}>
            Onayla
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={confirmUserModalVisible}
        onRequestClose={() => setConfirmUserModalVisible(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "12px",
            maxWidth: "400px",
            maxHeight: "230px",
            margin: "auto",
            textAlign: "center",
            gap: "20px",
            fontSize: "16px",
          },
        }}
      >
        <div className="confirmModal">
          <i className="fa-solid fa-circle-exclamation"></i>
          <span>
            Kullanıcıyı {selectedUser?.isActive ? "deaktif" : "aktif"} etmek
            istiyor musunuz ?
          </span>
        </div>
        <div className="modalButtons">
          <button
            className="btn"
            onClick={() => setConfirmUserModalVisible(false)}
          >
            İptal
          </button>
          <button
            className="btn"
            onClick={() =>
              changeActivity(selectedUser?.isActive ? false : true)
            }
          >
            Onayla
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default UsersTable;
