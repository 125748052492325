import React from "react";
import "./Introduction.css";

function Introduction() {
  return (
    <div className="introduction" id="introduction">
      <div className="content">
        <div className="leftSide" data-aos="fade-right">
          <h2 className="badgeText">Önsöz</h2>
          <h3>Hoş geldiniz!</h3>

          <p>
            “Price Action ile Sıfırdan Fon Yönetimine” adlı eğitim kursu
            amacıyla burada bulunmanız benim için büyük bir mutluluk. Finansal
            piyasalarda başarılı olmanın temelini oluşturan teknik analiz
            yöntemlerinden biri olan Price Action, bu kurs boyunca sade bir
            anlatımla açıklanacak ve size finansal piyasalar dünyasında daha
            güçlü bir konum kazanma fırsatı sunacaktır.
          </p>

          <p>
            Bu eğitimde, grafiklerin dilini anlamak ve fiyat hareketlerinin
            arkasındaki gizli mesajları çözmek için gerekli bilgileri
            bulacaksınız. Aynı zamanda kendi trade stratejinizi oluşturmak için
            de gereken becerilere de sahip olacaksınız.
          </p>

          <p>
            Bu web sitesine ücretli kayıt yaptırdığınızda, size özel bir erişim
            hesabı oluşturulacak ve eğitim videolarına tam erişim hakkı
            kazanacaksınız. Essiloss’un trade stratejilerini adım adım
            öğrenirken, bu bilgileri uygulamak için özel hazırlanmış pratik
            alıştırmalarla deneyim kazanacaksınız.
          </p>

          <p>
            Benimle birlikte bu yolculuğa çıkarken, başarıya giden yolda size
            rehberlik etmekten ve finansal hedeflerinize ulaşmanıza yardımcı
            olmaktan mutluluk duyacağım. Başlamak için şimdi kayıt olun ve trade
            dünyasında kendinizi keşfedin!
          </p>

          <p>Başarılar ve bol kazançlar dilerim.</p>
          <p>
            <br />
            Saygılarımla,
            <br />
            Essiloss
          </p>
        </div>
        <div className="rightSide" data-aos="fade-left">
          <img src="assets/essiloss.png" alt="logo" />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
